import React from "react";

const Buttons = () => {
  return (
    <div className="flex flex-col md:flex-row gap-10 md:gap-16 justify-center mt-8 mb-16 font-semibold text-white">
      <button className="text-3xl md:text-5xl hover:underline">
        <a target="_blank">Buy $ROSA</a>
      </button>
      <button className="text-3xl md:text-5xl hover:underline">
        <a target="_blank">Dexscreener</a>
      </button>
      <button className="text-3xl md:text-5xl hover:underline">
        <a target="_blank" href="https://x.com/rosapumps_sol">
          Rosa Pumps on X
        </a>
      </button>
    </div>
  );
};

export default Buttons;
