import React from "react";

const About = () => {
  return (
    <div className="px-6 md:px-40 flex flex-col md:flex-row p-10 gap-12 w-full justify-between items-center bg-navyish text-white">
      <div className="md:flex-1 text-center md:text-left">
        <h1 className="text-4xl md:text-5xl mb-4 font-semibold">PUMP THE BRAKES</h1>
        <p className="text-xl md:text-2xl">
          With Juneteenth right around the corner, there is no better time to pay homage to one of the most important civil rights icons to ever live: Rosa Pumps. 
        </p>
      </div>
      <img
        className="w-64 md:w-100 h-64 md:h-80 rounded-lg object-cover"
        src="pumps-cropped.png"
        alt="bus"
      />
    </div>
  );
};

export default About;
