
import './App.css';
import NavBar from './NavBar';
import CA from './CA';        
import TopCarousel from './carousel/TopCarousel';
import About from './About';
import HowToBuy from './HowToBuy';
import BurnCount from './BurnCount';
import MemeGallery from './MemeGallery';
import Footer from './Footer';
import Buttons from './Buttons';

function App() {
  return (
    <div className="App font-annie bg-navyish">
      <NavBar />
      <img
        className="w-full h-auto max-h-60 min-h-96 object-contain"
        src="nah-banner.jpg"
        alt="Nah Banner"
      />
      <div className="flex flex-col mt-10">
        {/* <TopCarousel /> */}
        <Buttons />

        <CA />

        <About />
        <HowToBuy />
        {/* <BurnCount /> */}
        {/* <MemeGallery /> */}
        <img
          className="w-full  min-h-80 object-fill"
          src="pumps-prize.png"
          alt="Nah Banner"
        />
        <Footer />
      </div>
    </div>
  );
}

export default App;
