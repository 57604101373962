import React from "react";

const HowToBuy = () => {
  return (
    <div className="relative text-white bg-blue-800 h-auto flex items-start justify-center py-10">
      {/* Background Image and Overlay */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(bus-inside.jpeg)`,
          filter: "brightness(0.5) contrast(1.2) blur(2px)",
        }}
      >
        <div className="absolute inset-0 bg-blue-800 opacity-30"></div>{" "}
        {/* Blue Overlay */}
      </div>

      {/* Content */}
      <div className="relative px-10 py-12 w-full max-w-6xl flex flex-col md:flex-row gap-10">
        {/* Left Section */}
        <div className="flex-1 flex flex-col items-center justify-start text-center md:text-left">
          <h2 className="text-6xl md:text-8xl font-bold mb-6">How to buy</h2>
          <img
            className="w-100 mb-6"
            src="pumps-driver.jpg"
            alt="Character Image"
          />
        </div>

        {/* Right Section */}
        <div className="flex-1 pl-0 md:pl-10">
          <h2 className="text-3xl md:text-4xl font-bold mb-2">
            CREATE A WALLET
          </h2>
          <p className="mb-4 text-lg md:text-xl font-semibold">
            If you are utilizing a desktop browser like Chrome or Firefox,
            download a wallet extension from the associates store. If on a
            mobile device, download the wallet from your device's app store. We
            recommend Phantom.
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://phantom.app/download"
          >
            <button className="bg-phantom hover:bg-blue-300 text-black font-bold py-2 px-4 rounded mb-6">
              Download Phantom
            </button>
          </a>

          <h2 className="text-3xl md:text-4xl font-bold mt-6 mb-2">
            GET SOL
          </h2>
          <p className="mb-4 text-lg md:text-xl font-semibold">
           To buy $ROSA, you need some SOL. Buy it either directly in your
            Phantom wallet or on another exchange like Binance,
            Coinbase, or OKX and send it to your wallet.
          </p>

          <h2 className="text-3xl md:text-4xl font-bold mt-6 mb-2">
            NAVIGATE TO RAYDIUM
          </h2>
          <p className="mb-4 text-lg md:text-xl font-semibold">
            Got to the Raydium site and connect your wallet. Paste the $ROSA contract address into Raydium,
            select $ROSA, and hit confirm. Sign when Raydium prompts your wallet for a signature.
          </p>

          <h2 className="text-3xl md:text-4xl font-bold mt-6 mb-2">
            BUY $ROSA
          </h2>
          <p className="mb-4 text-lg md:text-xl font-semibold">
            Swap your SOL for $ROSA on Raydium!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;
