import React from "react";
import {
  FaTelegramPlane,
  FaTwitter,
  FaInstagram,
  FaDiscord,
} from "react-icons/fa"; // You can use Font Awesome or other icon libraries
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="bg-phantom text-white py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Logo and Main Content */}
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
          {/* Logo Section */}
          <div className="flex flex-col items-center md:items-start mb-6 md:mb-0">
            <div className="text-center md:text-left mb-4">
              <h2 className="text-4xl font-bold">$ROSA PUMPS</h2>
              {/* <p className="text-lg">mek memes gret agen</p> */}
            </div>
          </div>

          {/* Social Media Icons */}
          <div className="flex space-x-6 mb-6 md:mb-0">
            <a
              href="https://x.com/rosapumps_sol"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white"
            >
              <FaXTwitter className="w-8 h-8" />
            </a>
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
              className="text-white"
            >
              <FaTelegramPlane className="w-8 h-8" />
            </a>
          </div>

          {/* Contact Information */}
        </div>

        {/* Divider */}
        <div className="my-8 border-t border-white"></div>

        {/* Legal Disclaimer */}
        <div className="text-center text-lg">
          <p>
            $ROSA has no association with Rosa Parks or her estate. This token
            is simply a memecoin with no intrinsic value and should not carry an
            expectation of financial return.
          </p>
          <p className="mt-2">
            © 2024 by rosa pumps $ROSA. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
