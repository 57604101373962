import React from "react";
import "./App.css"; // Ensure you import the CSS where you defined the animation
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";

const NavBar = () => {
  return (
    <div className="flex justify-between items-center px-8 mb-8 text-white">
      
        <img
          className="w-20" // Apply the animation class directly
          src="pumps-transparent.png"
          alt="Spinning logo"
        />
      
      <h2 className="text-6xl md:text-7xl font-semibold text-white">$ROSA</h2>

      <div className="flex space-x-6 mb-6 md:mb-0 hidden md:flex">
        {/* Hidden on small screens and visible on medium and larger screens */}
        <a
          href="https://x.com/rosapumps_sol"
          target="_blank"
          rel="noopener noreferrer"
          className="text-black"
        >
          <FaXTwitter className="w-8 h-8 text-white" />
        </a>
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
          className="text-black"
        >
          <FaTelegramPlane className="w-8 h-8 text-white" />
        </a>
      </div>
    </div>
  );
};

export default NavBar;
